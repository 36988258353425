import React from 'react';
import LayoutPanel from 'components/layout/layoutPanel';
import Image from 'components/common/Image';
import { LinkModal } from 'kh-common-components';
import { modalContents } from '../../constants/modal-content';

function flybuysInfo (props) {

  return (
    
    <LayoutPanel background={props.panelBackground}
                 padding={props.panelPadding}
                 {...props}>
        <div className="container">
            <div className="row mer-panel">
                <div className="mer-panel__images col-5 col-lg-2 col-sm-3 mb-0 mr-lg-5">
                    <Image className="mer-panel__image"
                         src="/flybuys-points-1.png"
                         alt="Flybuys 1 point per $1 spent"
                    />
                </div>
                <div className="content col-10 col-xl-5 col-md-6 col-sm-7 offset-sm-1 offset-lg-0">
                    <h3>Earn Flybuys points</h3>
                    <p>Earn Flybuys points with every Kleenheat bill. Simply add your Flybuys number to your Kleenheat natural gas account you’ll earn one point for every dollar spent on gas*.</p>
                    <div>                  
                      <small><LinkModal {...props}
                        modalContents={modalContents}
                        useModal="true"
                        title="Flybuys terms & conditions"
                        // linkClass=""
                        displayContent="*Terms and conditions apply"
                        modalTitle="Flybuys terms & conditions"
                        modalContentName="flybuys-terms"
                        okayButtonLabel="Okay"
                      /></small>
                    </div>
                </div>
            </div>
      </div>
    </LayoutPanel>
    
  )
}

export default flybuysInfo;