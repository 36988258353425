import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import LayoutPanel from 'components/layout/layoutPanel';
import ProductSelect from '../common/ProductSelect';
import { LinkModal } from 'kh-common-components';
import { modalContents } from '../../constants/modal-content';

function ProductsInfo (props) {
    const [plansData, setPlansData] = useState(null);

    const getPlanData = async () => {

        if (plansData !== null) {
            return;
        }

        try {
            const plans = await Axios.get(process.env.PRODUCTS_API);
            const reversed = plans.data.reverse();
            setPlansData(reversed);
        } catch (error) {
            console.log('Error getting plan details');
        }
    };

    useEffect(() => {
        getPlanData();
    });

    return (
        <>
        {Array.isArray(plansData) && plansData.length > 0 &&
            <LayoutPanel background={props.panelBackground}
                         padding={props.panelPadding}
                         id="products-info">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 content text-center">
                            <h2>Natural gas plans and pricing.</h2>
                        </div>
                    </div>
                </div>
                <ProductSelect 
                    equalHeight={true}
                    data={plansData}
                />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <div>                  
                                <small><LinkModal {...props}
                                    modalContents={modalContents}
                                    useModal="true"
                                    title="Prices and fees for all natural gas customers"
                                    // linkClass=""
                                    displayContent="View prices and fees for all natural gas customers"
                                    modalTitle="Prices and fees for all natural gas customers"
                                    modalContentName="standard_prices_and_fees"
                                    okayButtonLabel="Okay"
                                /></small>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutPanel>
        }</>
    )
}

export default ProductsInfo;