import React from 'react';

import LayoutPanel from 'components/layout/layoutPanel';

import Link from 'components/common/Link';
import { LinkAnchor } from 'components/common/LinkAnchor';
import Image from 'components/common/Image';

import heroBanner from 'images/natural-gas/hero-cupcake.png';

function signupNgInfo (props) {

  return (
    <LayoutPanel background={props.panelBackground}
                padding={props.panelPadding}
                {...props}
                id="signup-ng-info">
        <div className="container">
            <div className="row mer-panel align-items-center justify-content-around">
                <div className="col-10 col-sm-6 col-md-6 col-lg-5 offset-lg-1 order-2 order-sm-1 mer-text--size-lg">
                    <div className="content">
                        <h1>Switch to award-winning local service.</h1>
                        <p>We've received Canstar Blue's award for Most Satisfied Customers - WA Natural Gas for the second year in a row!</p>

                        <div className="mer-panel__actions mer-button-group">
                            <Link text="Sign up"
                                link="/sign-up"
                                linkClass="mer-button mer-button--primary" />
                            { props.ctaLinkAnchor && 
                                <LinkAnchor 
                                    to="/service-availability#products-info" 
                                    title="View plans" 
                                    stripHash={true} 
                                    offset={-100} 
                                    className="mer-button mer-button--secondary"
                                    />
                            }
                            { !props.ctaLinkAnchor && 
                                    <Link text="View plans"
                                    link="#products-info"
                                    linkClass="mer-button mer-button--secondary" />
                            }
                        </div>
                    </div>
                </div>
                <div className="mer-panel__images hero-banner col-8 col-sm-6 col-md-6 order-1 order-sm-2">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-10 col-lg-8 text-center py-0">
                            <img src={heroBanner} 
                                className="mer-panel__image"
                                alt="YAY cupcakes" />
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-5 col-sm-3 py-0">
                                <a href="#awards-info" 
                                    title="Click to view 2023 Most satisfied customers WA award" 
                                    className="mer-link--hover-none">
                                    <Image className="mer-panel__image"
                                        src="/canstar-ng-23-customers.png"
                                        alt="2023 Most satisfied customers WA award"
                                    />
                                </a>
                        </div>
                        <div className="col-5 col-sm-3 py-0">
                            <a href="#awards-info" 
                                title="Open to view 2022 Most satisfied customers WA award" 
                                className="mer-link--hover-none">
                                <Image className="mer-panel__image"
                                    src="/canstar-ng-22.png"
                                    alt="2022 Most satisfied customers WA award"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </LayoutPanel>
  )
}

export default signupNgInfo;