import React from 'react';
import Image from 'components/common/Image';
import LayoutPanel from 'components/layout/layoutPanel';

function ProductHighlight (props) {

    return (
        <LayoutPanel background={props.panelBackground}
                     padding={props.panelPadding}>
            <div className="container">
                <div className="row justify-content-lg-between justify-content-center mer-text--size-lg">
                    <div className="col-12 col-lg-3">
                        <div className="row justify-content-center">
                            <div className="col-4 col-sm-2 col-lg-6">
                                <Image className="mer-panel__image"
                                    src="/icons/flowmoji/flowmoji-wa.svg"
                                    alt="Flowmoji Western Australia"
                                />
                            </div>
                            <div className="col-8 col-sm-4 col-lg-12 text-lg-center">
                                <h5 className="my-0">WA born and bred</h5>
                                <p>We've been at home in WA for over 65 years.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="row justify-content-center">
                            <div className="col-4 col-sm-2 col-lg-6">
                                <Image className="mer-panel__image"
                                    src="/icons/flowmoji/flowmoji-piggybank.svg"
                                    alt="Flowmoji search"
                                />
                            </div>
                            <div className="col-8 col-sm-4 col-lg-12 text-lg-center">
                                <h5 className="my-0">Ongoing value</h5>
                                <p>Plus, added benefits through our partnerships with RAC and Flybuys*.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="row justify-content-center">
                            <div className="col-4 col-sm-2 col-lg-6">
                                <Image className="mer-panel__image"
                                    src="/canstar-ng-23.png"
                                    alt="Canstar 2023"
                                />
                            </div>
                            <div className="col-8 col-sm-4 col-lg-12 text-lg-center">
                                <h5 className="my-0">Award-winning service</h5>
                                <p>Our local team are here to make gas easy for you.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutPanel>
    );
}

export default ProductHighlight;